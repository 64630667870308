import React from 'react'

const HelperText = ({ error, helperText }) => {
  return helperText ? (
    <p
      style={{
        margin: 0,
        fontSize: '0.75rem',
        marginTop: '3px',
        textAlign: 'left',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontWeight: 400,
        lineHeight: 1.66,
        color: error ? '#f44336' : '#757575',
        marginLeft: '14px'
      }}
    >
      {helperText}
    </p>
  )
    :
    <></>
}

export default HelperText