import React, { useEffect, useState } from "react";
import CustomTabs from "components/CustomTabs/CustomTabs";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { useRecoilValue, useRecoilState } from "recoil";
import Loading from "components/Loading/Loading";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import update from "immutability-helper";
import { Add, Close, Edit, Folder, DateRange, Delete, FolderOpen, LocalOffer, Cancel, HighlightOff, AddBox, Height } from "@material-ui/icons";
import {
  getCampuses,
  createBuilding,
  updateBuilding,
  deleteBuilding,
  getBuildings,
  setOpenHours
} from "state/events";
import Tags from "components/Tags/Tags";
import './RoomManager.css'
import HelperText from "components/HelperText/HelperText";

const Buildings = ({ campuses, buildings, setBuildings }) => {
  const [toggleForm, setToggleForm] = useState(false)
  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ]
  const emptyTimeSlot = {
    start: '',
    end: ''
  }
  const emptyOpenHour = {
    type: 'normal',
    days: [],
    startDate: '',
    endDate: '',
    name: '',
    timeslots: []
  }
  const emptyBuilding = {
    name: '',
    location: '',
    campus: campuses.length > 0 ? campuses[0] : null,
    tags: [],
    files: [],
    openHours: []
  }

  const [building, setBuilding] = useState(emptyBuilding)
  const [submitting, setSubmitting] = useState(false)

  const usedDaysOfWeek = building.openHours.map((oh) => oh.days).flat(1)

  const emptyError = {
    hasError: false,
    message: ''
  }

  const emptyOpenHoursError = {
    type: emptyError,
    days: emptyError,
    startDate: emptyError,
    endDate: emptyError,
    timeslots: []
  }

  const getEmptyErrors = (b = building) => {
    return {
      name: emptyError,
      location: emptyError,
      tags: emptyError,
      campus: emptyError,
      openHours: b.openHours.map((oh) => {
        let ohErrors = {
          type: emptyError,
          days: emptyError,
          startDate: emptyError,
          endDate: emptyError,
          timeslots: oh.timeslots.map(ts => emptyError)
        }
        return ohErrors
      })
    }
  }

  const [errors, setErrors] = useState(getEmptyErrors())

  const formattedBuildings = buildings.map(b => {
    let campus = campuses.find(c => c.id === b.campusID)
    let item = {
      ...b,
      campusName: campus ? campus.name : '',
      tagsIcon: (
        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
          {(b.tags.length > 0) && (
            <div title={b.tags.join('\n')} style={{ cursor: 'pointer', color: '#3695d7', display: 'flex', alignItems: 'center' }}>
              <LocalOffer style={{ fontSize: '22px' }} />
            </div>
          )}
        </div>
      ),
      actions: (
        <React.Fragment>
          <Button
            style={{ margin: '2px' }}
            justIcon
            color="primary"
            onClick={() => {
              let building = {
                ...b,
                campus: campus
              }
              setBuilding(building)
              setErrors(getEmptyErrors(b))
              setToggleForm(true)
            }}
            title='Edit'
          >
            <Edit />
          </Button>

          <Button
            style={{ margin: '2px' }}
            justIcon
            color="danger"
            onClick={async () => {
              if (confirm(`Delete Building ${b.name}`)) {
                await deleteBuilding({ id: b.id })
                let temp = await getBuildings()
                setBuildings(temp)
              }
            }}
            title='Delete'
          >
            <Delete />
          </Button>
        </React.Fragment>
      )
    }
    return item
  })

  const cols = [
    {
      Header: "Name",
      accessor: "name",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "",
      accessor: "tagsIcon",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '22px', padding: '0', fontSize: '12px' },
      headerStyle: { maxWidth: '22px', padding: '0', margin: '0px' }
    },
    {
      Header: "Campus",
      accessor: "campusName",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Location",
      accessor: "location",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "",
      accessor: "actions",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }
    },
  ]

  const setTag = (v) => {
    setBuilding((prevState) => {
      return update(prevState, {
        tags: { $push: [v] }
      })
    })
  }
  const setTags = (v) => {
    setBuilding((prevState) => {
      return update(prevState, {
        tags: { $set: v }
      })
    })
  }

  const isFormValid = () => {
    setErrors(getEmptyErrors())
    let valid = true

    if (building.name.trim() === '') {
      valid = false
      setErrors((prev) => {
        return update(prev, {
          name: {
            $merge: {
              hasError: true,
              message: '*Required'
            }
          }
        })
      })
    }

    if (building.location.trim() === '') {
      valid = false
      setErrors((prev) => {
        return update(prev, {
          location: {
            $merge: {
              hasError: true,
              message: '*Required'
            }
          }
        })
      })
    }

    if (!building.campus) {
      valid = false
      setErrors((prev) => {
        return update(prev, {
          campus: {
            $merge: {
              hasError: true,
              message: '*Required'
            }
          }
        })
      })
    }

    building.openHours.forEach((oh, index) => {
      if (!oh.type) {
        valid = false
        setErrors((prev) => {
          return update(prev, {
            openHours: {
              [index]: {
                type: {
                  $merge: {
                    hasError: true,
                    message: '*Required'
                  }
                }
              }
            }
          })
        })
      } else {
        if (oh.type == 'normal') {
          if (oh.days.length === 0) {
            valid = false
            setErrors((prev) => {
              return update(prev, {
                openHours: {
                  [index]: {
                    days: {
                      $merge: {
                        hasError: true,
                        message: '*Required'
                      }
                    }
                  }
                }
              })
            })
          }
        }
        if (oh.type === 'special') {
          if (!oh.startDate) {
            valid = false
            setErrors((prev) => {
              return update(prev, {
                openHours: {
                  [index]: {
                    startDate: {
                      $merge: {
                        hasError: true,
                        message: '*Required'
                      }
                    }
                  }
                }
              })
            })
          }
          if (!oh.endDate) {
            valid = false
            setErrors((prev) => {
              return update(prev, {
                openHours: {
                  [index]: {
                    endDate: {
                      $merge: {
                        hasError: true,
                        message: '*Required'
                      }
                    }
                  }
                }
              })
            })
          }
          if (oh.startDate && oh.endDate) {
            if (oh.startDate >= oh.endDate) {
              setErrors((prev) => {
                return update(prev, {
                  openHours: {
                    [index]: {
                      endDate: {
                        $merge: {
                          hasError: true,
                          message: '*End must be greater than start'
                        }
                      }
                    }
                  }
                })
              })
            }
          }
        }
      }

      oh.timeslots.forEach((ts, tsIdx) => {
        if (oh.timeslots.find((i, iIDX) => ((i.start <= ts.start && i.end >= ts.end) || (i.start <= ts.end && i.end >= ts.end)) && iIDX !== tsIdx)) {
          valid = false
          setErrors((prev) => {
            return update(prev, {
              openHours: {
                [index]: {
                  timeslots: {
                    [tsIdx]: {
                      $merge: {
                        hasError: true,
                        message: "*Timeslots can't overlap."
                      }
                    }
                  }
                }
              }
            })
          })
        }
        if (!ts.start && !ts.end) {
          valid = false
          setErrors((prev) => {
            return update(prev, {
              openHours: {
                [index]: {
                  timeslots: {
                    [tsIdx]: {
                      $merge: {
                        hasError: true,
                        message: '*Required'
                      }
                    }
                  }
                }
              }
            })
          })
        } else if (!ts.start) {
          valid = false
          setErrors((prev) => {
            return update(prev, {
              openHours: {
                [index]: {
                  timeslots: {
                    [tsIdx]: {
                      $merge: {
                        hasError: true,
                        message: '*Start cannot be empty'
                      }
                    }
                  }
                }
              }
            })
          })
        } else if (!ts.end) {
          valid = false
          setErrors((prev) => {
            return update(prev, {
              openHours: {
                [index]: {
                  timeslots: {
                    [tsIdx]: {
                      $merge: {
                        hasError: true,
                        message: '*End cannot be empty'
                      }
                    }
                  }
                }
              }
            })
          })
        }
        if (ts.start !== '' && ts.end !== '') {
          if (ts.start >= ts.end) {
            valid = false
            setErrors((prev) => {
              return update(prev, {
                openHours: {
                  [index]: {
                    timeslots: {
                      [tsIdx]: {
                        $merge: {
                          hasError: true,
                          message: '*End must be greater than start'
                        }
                      }
                    }
                  }
                }
              })
            })
          }
        }
      })
    })
    return valid
  }

  const submitForm = async () => {
    if (isFormValid()) {
      setSubmitting(true)
      let b = {
        name: building.name,
        location: building.location,
        tags: building.tags,
        campusID: building.campus.id
      }

      if (building.id) {
        b.id = building.id
        await updateBuilding(b)
      } else {
        let buildingID = await createBuilding(b)
        b.id = buildingID
      }

      let openHours = building.openHours.map(oh => {
        return {
          type: oh.type,
          days: oh.days,
          startDate: oh.startDate,
          endDate: oh.endDate,
          forType: 'building',
          forID: b.id,
          timeslots: oh.timeslots
        }
      })
      await setOpenHours({
        openHours: openHours,
        forType: 'building',
        forID: b.id
      })
      let temp = await getBuildings()
      setBuildings(temp)
      setBuilding(emptyBuilding)
      setErrors(getEmptyErrors(emptyBuilding))
      setSubmitting(false)
    }
  }

  const setOpenHoursType = (index, value) => {
    setBuilding((prevState) => {
      return update(prevState, {
        openHours: { [index]: { type: { $set: value } } }
      })
    })
    if (value === 'normal') {
      setBuilding((prevState) => {
        return update(prevState, {
          openHours: { [index]: { $merge: { startDate: '', endDate: '' } } }
        })
      })
    } else {
      setBuilding((prevState) => {
        return update(prevState, {
          openHours: { [index]: { days: { $set: [] } } }
        })
      })
    }
  }

  const removeOpenHours = (index) => {
    setBuilding(prev => {
      return update(prev, {
        openHours: { $splice: [[index, 1]] }
      })
    })

    setErrors(prev => {
      return update(prev, {
        openHours: { $splice: [[index, 1]] }
      })
    })
  }

  return (
    <React.Fragment>
      <div style={{ textAlign: 'right' }}>
        {!toggleForm && (
          <Button
            color='info'
            onClick={(e) => {
              e.preventDefault()
              setToggleForm(true)
            }}
            justIcon
            title='Create New'
          >
            <Add />
          </Button>
        )}
        {toggleForm && (
          <Button
            color='danger'
            onClick={(e) => {
              e.preventDefault()
              setBuilding(emptyBuilding)
              setErrors(getEmptyErrors(emptyBuilding))
              setToggleForm(false)
            }}
            justIcon
            title='Close'
          >
            <Close />
          </Button>
        )}
      </div>
      <div style={{
        maxHeight: toggleForm ? `${(600 + (450 * building.openHours.length))}px` : '0px',
        minHeight: toggleForm ? `${(200 + (150 * building.openHours.length))}px` : '0px',
        opacity: toggleForm ? 1 : 0,
        transition: 'all 2000ms ease-out',
        position: 'relative',
        zIndex: 1,
        // overflow: 'auto'
      }}>
        {toggleForm && (
          <>
            {!submitting ?
              <div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <TextField
                    style={{ margin: '5px', flex: 1, minWidth: '225px' }}
                    label='Name'
                    required
                    fullWidth
                    variant='outlined'
                    error={errors.name.hasError}
                    helperText={errors.name.message}
                    value={building.name}
                    onChange={(e) => setBuilding(prev => {
                      return update(prev, {
                        name: { $set: e.target.value }
                      })
                    })}
                  />
                  <TextField
                    style={{ margin: '5px', flex: 1, minWidth: '225px' }}
                    label='Location'
                    fullWidth
                    required
                    variant='outlined'
                    value={building.location}
                    error={errors.location.hasError}
                    helperText={errors.location.message}
                    onChange={(e) => setBuilding(prev => {
                      return update(prev, {
                        location: { $set: e.target.value }
                      })
                    })}
                  />
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div style={{ margin: '5px', flex: 1, minWidth: '225px' }}>
                    <Autocomplete
                      disablePortal
                      options={campuses}
                      value={building.campus}
                      ListboxProps={
                        {
                          style: {
                            maxHeight: '290px',
                          }
                        }
                      }
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option) => building.campus.id === option.id}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Campus"
                          variant="outlined"
                          error={errors.campus.hasError}
                          helperText={errors.campus.message}
                        />
                      )}
                      fullWidth
                      onChange={(e, v) => {
                        setBuilding(prev => {
                          return update(prev, {
                            campus: { $set: v }
                          })
                        })
                      }}
                      autoSelect={true}
                      autoComplete={true}
                      autoHighlight={true}
                      disableClearable
                    />
                  </div>
                  <div style={{ margin: '5px', flex: 1, minWidth: '225px' }}>
                    <Tags
                      tags={building.tags}
                      setTag={setTag}
                      setTags={setTags}
                      variant={'outlined'}
                      label={'Tags'}
                      error={errors.tags.hasError}
                      helperText={errors.tags.message}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <span
                      style={{
                        margin: '0px 5px',
                        color: '#757575',
                        backgroundColor: '#fff',
                        fontSize: '12px',
                      }}
                    >
                      Open Hours
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    {building.openHours.length > 0 ?
                      <>
                        {building.openHours.map((item, index) => {
                          return (
                            <div style={{ display: 'flex' }} key={index}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  borderRadius: "3px",
                                  background: "#fff",
                                  padding: "10px 5px 0px 5px",
                                  border: "1px solid #c4c4c4",
                                  margin: index === 0 ? '0px 5px 5px' : '5px',
                                  flex: '1'
                                }}
                              >
                                <div style={{ margin: '0px 0px 5px 0px', flex: 1, minWidth: '225px', display: 'flex', flexDirection: 'column' }}>
                                  <div style={{ margin: '5px' }}>
                                    <Autocomplete
                                      disablePortal
                                      options={['normal', 'special']}
                                      value={item.type}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          required label="Type"
                                          variant="outlined"
                                          error={errors.openHours[index].type.hasError}
                                          helperText={errors.openHours[index].type.message}
                                        />
                                      )}
                                      fullWidth
                                      onChange={(e, v) => {
                                        setOpenHoursType(index, v)
                                      }}
                                      autoSelect={true}
                                      autoComplete={true}
                                      autoHighlight={true}
                                      disableClearable
                                    />
                                  </div>
                                  {(item.type === 'normal') && (
                                    <div style={{ margin: '5px' }}>
                                      <Autocomplete
                                        disablePortal
                                        multiple
                                        options={daysOfWeek.filter(d => (usedDaysOfWeek.filter(i => item.days.indexOf(i) === -1)).indexOf(d) === -1)}
                                        value={item.days}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            required
                                            label="Days"
                                            variant="outlined"
                                            error={errors.openHours[index].days.hasError}
                                            helperText={errors.openHours[index].days.message}
                                          />
                                        )}
                                        ListboxProps={
                                          {
                                            style: {
                                              maxHeight: '130px',
                                            }
                                          }
                                        }
                                        fullWidth
                                        onChange={(e, v) => {
                                          setBuilding(prev => {
                                            return update(prev, {
                                              openHours: { [index]: { days: { $set: v } } }
                                            })
                                          })
                                        }}
                                        autoSelect={true}
                                        autoComplete={true}
                                        autoHighlight={true}
                                      // disableClearable
                                      />
                                    </div>
                                  )}
                                  {(item.type === 'special') && (
                                    <div style={{ flex: 1, minWidth: '225px' }}>
                                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        <TextField
                                          variant="outlined"
                                          type="date"
                                          value={item.startDate}
                                          label='Start Date'
                                          error={errors.openHours[index].startDate.hasError}
                                          helperText={errors.openHours[index].startDate.message}
                                          required
                                          style={{ margin: '5px', flex: 1, minWidth: '160px' }}
                                          onChange={(e) => {
                                            setBuilding(prev => {
                                              return update(prev, {
                                                openHours: { [index]: { startDate: { $set: e.target.value } } }
                                              })
                                            })
                                          }}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                        />
                                        <TextField
                                          variant="outlined"
                                          type="date"
                                          value={item.endDate}
                                          label='End Date'
                                          required
                                          error={errors.openHours[index].endDate.hasError}
                                          helperText={errors.openHours[index].endDate.message}
                                          style={{ margin: '5px', flex: 1, minWidth: '160px' }}
                                          onChange={(e) => {
                                            setBuilding(prev => {
                                              return update(prev, {
                                                openHours: { [index]: { endDate: { $set: e.target.value } } }
                                              })
                                            })
                                          }}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div style={{ margin: '0px', flex: 1, minWidth: '225px' }}>
                                  <div style={{ margin: '-6px 0px 0px 15px' }}>
                                    <span style={{
                                      position: 'relative',
                                      margin: '0px',
                                      padding: '0px 5px',
                                      color: '#757575',
                                      backgroundColor: '#fff',
                                      zIndex: 2,
                                      fontSize: '12px'
                                    }}>
                                      Timeslots *
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      position: 'relative',
                                      borderRadius: "3px",
                                      background: "#fff",
                                      padding: "5px",
                                      border: "1px solid #c4c4c4",
                                      margin: '5px',
                                      marginBottom: '10px',
                                      zIndex: 1,
                                      marginTop: '-11px',
                                      display: 'flex',
                                      flexDirection: 'column'
                                    }}
                                  >
                                    {item.timeslots.length === 0 && (
                                      <div style={{
                                        display: 'flex',
                                        borderRadius: "3px",
                                        background: "#fff",
                                        padding: "5px",
                                        border: "1px solid #c4c4c4",
                                        margin: '5px',
                                        justifyContent: 'center'
                                      }}>
                                        <span>Closed</span>
                                      </div>
                                    )}
                                    {item.timeslots.map((slot, slotIndex) => {
                                      return (
                                        <div style={{ margin: '5px' }} key={slotIndex}>
                                          <div
                                            style={{
                                              display: 'flex',
                                              borderRadius: "3px",
                                              background: "#fff",
                                              padding: "0px 5px",
                                              border: errors.openHours[index].timeslots[slotIndex].hasError ? '1px solid #f44336' : "1px solid #c4c4c4",
                                            }}>
                                            <div style={{ display: 'flex', flexWrap: 'wrap', flex: 1, alignItems: 'center' }}>
                                              <TextField
                                                style={{ margin: '5px', flex: 1, minWidth: '105px' }}
                                                type="time"
                                                value={slot.start}
                                                fullWidth
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                                onChange={(e) => {
                                                  setBuilding(prev => {
                                                    return update(prev, {
                                                      openHours: { [index]: { timeslots: { [slotIndex]: { start: { $set: e.target.value } } } } }
                                                    })
                                                  })
                                                }}
                                              />
                                              <span style={{ margin: '5px' }}>to</span>
                                              <TextField
                                                value={slot.end}
                                                style={{ margin: '5px', flex: 1, minWidth: '105px' }}
                                                type="time"
                                                fullWidth
                                                onChange={(e) => {
                                                  setBuilding(prev => {
                                                    return update(prev, {
                                                      openHours: { [index]: { timeslots: { [slotIndex]: { end: { $set: e.target.value } } } } }
                                                    })
                                                  })
                                                }}
                                              />
                                            </div>
                                            <div
                                              title="Remove Timeslot"
                                              className="closeButtonRoomManager"
                                              onClick={(e) => {
                                                e.preventDefault()
                                                setBuilding(prev => {
                                                  return update(prev, {
                                                    openHours: { [index]: { timeslots: { $splice: [[slotIndex, 1]] } } }
                                                  })
                                                })

                                                setErrors(prev => {
                                                  return update(prev, {
                                                    openHours: { [index]: { timeslots: { $splice: [[slotIndex, 1]] } } }
                                                  })
                                                })
                                              }}
                                            >
                                              <Close />
                                            </div>
                                          </div>
                                          <HelperText error={errors.openHours[index].timeslots[slotIndex].hasError} helperText={errors.openHours[index].timeslots[slotIndex].message} />
                                        </div>
                                      )
                                    })}
                                    <div
                                      title='Add Timeslot'
                                      className="dashedAddButtonRoomManager"
                                      onClick={(e) => {
                                        e.preventDefault()
                                        setBuilding(prev => {
                                          return update(prev, {
                                            openHours: { [index]: { timeslots: { $push: [emptyTimeSlot] } } }
                                          })
                                        })

                                        setErrors(prev => {
                                          return update(prev, {
                                            openHours: { [index]: { timeslots: { $push: [emptyError] } } }
                                          })
                                        })
                                      }}
                                    >
                                      <div style={{ display: 'flex', flexWrap: 'wrap', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                                        <Add style={{ fontSize: '20px' }} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div style={{
                                display: 'flex',
                                maxWidth: '42px',
                                alignItems: 'center'
                              }}>
                                <Button
                                  color='danger'
                                  style={{

                                  }}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    removeOpenHours(index)
                                  }}
                                  justIcon
                                  title='Close'
                                >
                                  <Close />
                                </Button>
                              </div>
                            </div>
                          )
                        })}
                      </>
                      :
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          borderRadius: "3px",
                          background: "#fff",
                          padding: "5px",
                          border: "1px solid #c4c4c4",
                          margin: '0px 5px 5px',
                          flex: '1',
                          justifyContent: 'center'
                        }}
                      >
                        <span>Using campuses open hours.</span>
                      </div>
                    }
                    <div
                      title='Add Open Hours'
                      className="dashedAddButtonRoomManager"
                      onClick={(e) => {
                        e.preventDefault()
                        setBuilding(prev => {
                          return update(prev, {
                            openHours: { $push: [emptyOpenHour] }
                          })
                        })
                        setErrors(prev => {
                          return update(prev, {
                            openHours: { $push: [emptyOpenHoursError] }
                          })
                        })
                      }}
                    >
                      <div style={{ display: 'flex', flexWrap: 'wrap', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                        <Add style={{ fontSize: '20px' }} />
                      </div>
                    </div>

                  </div>

                </div>
                <div style={{ margin: '5px', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                  <Button
                    style={{ margin: '20px 0px' }}
                    color='primary'
                    title='Submit'
                    onClick={(e) => {
                      e.preventDefault()
                      submitForm()
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
              :
              <Loading color='blue' />
            }
          </>
        )}
      </div>
      <div style={{
        backgroundColor: 'white',
        position: 'relative',
        zIndex: 2
      }}>
        <ReactTable
          columns={cols}
          data={formattedBuildings}
        />
      </div>
    </React.Fragment>
  )
}

export default Buildings