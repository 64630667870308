import React from "react";
import Loading from "components/Loading/Loading";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import { getBudgetInfo } from "state/purchaseOrders";
import { useRecoilState } from "recoil";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import ReactTooltip from "react-tooltip";

const POBudgetWidgetComponent = (props) => {
  // const userGroups = useRecoilValue(groupsAtom)

  const isAdmin = props.isAdmin ? props.isAdmin : false

  const lineItems = props.lineItems

  const allCodes = lineItems.map(item => {
    return `${item.fundCode.code}-${item.glCode.code}-${item.deptCode.code}`
  })

  const uniqueCodes = allCodes.filter((code, index, array) => array.indexOf(code) === index)

  const codes = uniqueCodes.map(code => {
    let codeSplit = code.split('-')
    return {
      fund: codeSplit[0],
      gl: codeSplit[1],
      dept: codeSplit[2]
    }
  })

  const [budgets, setBudgets] = useRecoilState(getBudgetInfo(codes))

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });


  return (
    <React.Fragment>
      <GridContainer style={{ marginBottom: '-15px', marginTop: '10px' }}>
        {budgets.map((item, index) => {
          return (
            <GridItem key={index}>
              <Card>

                <CardHeader
                  color={item.discretionary === 'yes' ? 'warning' : item.discretionary === 'capitalized' ? 'danger' : 'primary'}
                  style={{ padding: '5px', textAlign: 'center' }}
                  data-tip
                  data-for={`budget-code-${index}`}
                >
                  <p style={{ marginBottom: '0px' }}><strong>{item.code.replaceAll('-', ' - ')}</strong></p>
                </CardHeader>
                <ReactTooltip
                  delayHide={50}
                  delayShow={50}
                  effect="solid"
                  place='top'
                  id={`budget-code-${index}`}
                >
                  {item.discretionary === 'yes' ? 'Discretionary Budget' : item.discretionary === 'capitalized' ? 'Capitalized Account' : 'Non-Discretionary Budget'}
                </ReactTooltip>
                <CardBody style={{ padding: '10px 25px 5px 25px' }}>
                  {item.discretionary === 'capitalized' && !isAdmin ?
                    <div style={{ textAlign: 'center', width: '142px', height: '115px' }}>
                      <p style={{ fontWeight: 'bold' }}>Capitalized Account</p>
                      <p style={{ fontSize: '12px' }}>Used for Exec Approved Projects Only.</p>
                    </div>
                    :
                    <>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p style={{ paddingRight: '10px' }}><strong>Budget: </strong></p>
                        <p>{item.budget ? formatter.format(item.budget) : 'No Budget Set.'}</p>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '-5px' }}>
                        <div style={{ textAlign: 'left', paddingRight: '10px' }}>
                          <p><strong>Spent:</strong></p>
                          <p style={{
                            color: 'grey',
                            fontSize: '11px',
                            marginTop: '-15px'
                          }}>
                            (w/pending)
                          </p>
                        </div>
                        <div style={{ textAlign: 'right' }}>
                          <p>{formatter.format(item.spent)}</p>
                          <p style={{
                            color: 'grey',
                            fontSize: '12px',
                            marginTop: '-15px'
                          }}>
                            ({formatter.format(item.spent + item.pending)})
                          </p>
                        </div>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '-5px' }}>
                        <div style={{ textAlign: 'left', paddingRight: '10px' }}>
                          <p><strong>Remaining:</strong></p>
                          <p style={{
                            color: 'grey',
                            fontSize: '11px',
                            marginTop: '-15px'
                          }}>
                            (w/pending)
                          </p>
                        </div>
                        {item.budget ?
                          <div style={{ textAlign: 'right' }}>
                            <p
                              style={item.budget - item.spent >= 0 ? {} : { color: 'red' }}>
                              {formatter.format(item.budget - item.spent)}</p>
                            <p style={{
                              color: item.budget - (item.spent + item.pending) < 0 ? 'red' : 'grey',
                              fontSize: '12px',
                              marginTop: '-15px'
                            }}>
                              ({formatter.format(item.budget - (item.spent + item.pending))})
                            </p>
                          </div>
                          : 'No Budget Set.'}
                      </div>
                    </>
                  }

                </CardBody>
              </Card>
            </GridItem>
          )
        })}
      </GridContainer>
    </React.Fragment>
  )
}

const POBudgetWidget = (props) => {
  const fallback = (
    <React.Fragment>
      <GridContainer style={{ marginBottom: '-15px', marginTop: '10px' }}>
        <GridItem>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <h4 style={{ fontWeight: 'bold' }}>Budget</h4>
              </CardIcon>
            </CardHeader>
            <CardBody>
              <Loading color="blue" />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  )
  return (
    <React.Suspense fallback={fallback}>
      <POBudgetWidgetComponent {...props} />
    </React.Suspense>
  )
}

export default POBudgetWidget