import React from "react";

const Portal = (props) => {
  return (
    <React.Suspense fallback={<p>Loading...</p>}>
      <p>Hello</p>
    </React.Suspense>
  );
};

export default Portal;
