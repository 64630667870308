import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Loading from "components/Loading/Loading";
import Visibility from "@material-ui/icons/Visibility";
import Edit from "@material-ui/icons/Edit";
import {
  Folder,
  FolderOpen,
} from "@material-ui/icons";
import update from "immutability-helper";


import ReactTooltip from "react-tooltip";

import FileListModal from "components/FileListModal/FileListModal";

import { useHistory } from "react-router";
import { encryptData } from "assets/jss/EncriptDecrypt/EncryptDecrypt";


const PurchaseOrderListUnderReviewComponent = (props) => {
  const history = useHistory();

  const [showFileListModal, setShowFileListModal] = useState(false);
  const [fileListModalId, setFileListModalId] = useState(null);

  const attachFileHandler = (id) => {
    setShowFileListModal(true);
    setFileListModalId(id);
  };

  const closeHandler = () => {
    setShowFileListModal(false);
    setFileListModalId(null);
    location.reload();
  };

  const viewClickHandler = (id) => {
    let data = {
      redirect: props.redirect,
    }

    history.push(`/portal/purchase-orders/view/${id}?${encryptData(data)}`);
  };

  const poList = props.items.map((item, index) => {
    let files = JSON.parse(item.attachedFiles);
    let newList = {
      ...item,
      actions: (
        <React.Fragment>
          <Button
            justIcon
            color="primary"
            onClick={() => viewClickHandler(item.id)}
            title='View'
          // data-tip
          // data-for={`view-item-${index}`}
          >
            <Visibility />
          </Button>
          {/* <ReactTooltip
            delayHide={50}
            delayShow={500}
            effect="solid"
            id={`view-item-${index}`}
          >
            View
          </ReactTooltip> */}
          {!props.reviewer && (
            <>
              <Button
                justIcon
                color="primary"
                href={`/portal/purchase-orders/edit/${item.id}?${props.redirect}`}
                // data-tip
                // data-for={`edit-item-${index}`}
                title='Edit'
              >
                <Edit />
              </Button>
              {/* <ReactTooltip
                delayHide={50}
                delayShow={500}
                effect="solid"
                id={`edit-item-${index}`}
              >
                Edit
              </ReactTooltip> */}

              <Button
                justIcon
                color="primary"
                // data-tip
                // data-for={`attach-item-${index}`}
                title='Manage Files'
                onClick={() => attachFileHandler(item.id)}
              >
                {files && files.length > 0 ? <Folder /> : <FolderOpen />}
              </Button>
              {/* <ReactTooltip
                delayHide={50}
                delayShow={500}
                effect="solid"
                id={`attach-item-${index}`}
              >
                Manage Files
              </ReactTooltip> */}
            </>
          )}
        </React.Fragment>
      ),
    };

    if (item.submittedTo2 !== null) {
      newList = update(newList, {
        $merge: {
          submittedToName: (
            `${item.submittedToName} & ${item.submittedTo2Name}`
          ),
        },
      });
    }

    newList = update(newList, {
      $merge: {
        total: "$" + parseFloat(item.total).toFixed(2),
      },
    });

    newList = update(newList, {
      $merge: {
        id: (
          <React.Fragment>
            {item.id}
            <br />
            <span style={{ color: "#f00", fontSize: "13px" }}>
              {item.status !== "review"
                ? `Status: ${item.statusWithName}`
                : ``}
            </span>
          </React.Fragment>
        ),
      },
    });

    return newList;
  });
  return (
    <GridContainer>
      <GridItem xs={12}>
        {showFileListModal && fileListModalId && (
          <FileListModal id={fileListModalId} closeHandler={closeHandler} />
        )}
        {poList.length > 0 && (
          <ReactTable
            columns={[
              {
                Header: "ID",
                accessor: "id",
                disableFilters: true,
                disableSortBy: true,
              },
              {
                Header: "Vendor",
                accessor: "vendor",
                disableFilters: false,
                disableSortBy: false,
              },
              {
                Header: "Date Submitted",
                accessor: "submittedDate",
                disableFilters: false,
                disableSortBy: false,
              },
              {
                Header: props.reviewer ? "Submitted By" : "Reviewer",
                accessor: props.reviewer
                  ? "submittedByName"
                  : "reviewerName",
                disableFilters: false,
                disableSortBy: false,
              },
              {
                Header: "Submitted To",
                accessor: "submittedToName",
                disableFilters: false,
                disableSortBy: false,
              },
              {
                Header: "Total",
                accessor: "total",
                disableFilters: false,
                disableSortBy: false,
              },
              {
                Header: "Actions",
                accessor: "actions",
                disableFilters: false,
                disableSortBy: false,
              },
            ]}
            data={poList}
          />
        )}

        {poList.length === 0 && (
          <p style={{ textAlign: "center" }}>{props.reviewer ? 'No Items to Review.' : 'No Under Review items.'}</p>
        )}
      </GridItem>
    </GridContainer>
  );
};

const PurchaseOrderListUnderReview = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <PurchaseOrderListUnderReviewComponent {...props} />
    </React.Suspense>
  );
};

export default PurchaseOrderListUnderReview;
