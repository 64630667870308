import React, { useEffect } from "react";
import axios from "axios";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  idTokenAtom,
  jwtAtom,
} from "../../state/auth";
import Cookies from "js-cookie";

import useReauth from "../../context/Reauth";


import { googleLogout } from '@react-oauth/google';

//request interceptor to add the auth token header to requests
axios.interceptors.request.use(
  (config) => {
    let accessToken = Cookies.get("StaffPortalJWT");
    if (!accessToken) {
      accessToken = sessionStorage.getItem("StaffPortalJWT")
    }
    if (!accessToken) {
      accessToken = sessionStorage.getItem("GuestEmail")
    }
    config.headers["x-auth-token"] = accessToken;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const AxiosInterceptor = (props) => {
  const [idToken, setIdToken] = useRecoilState(idTokenAtom);
  const refreshToken = useReauth();
  const jwt = useRecoilValue(jwtAtom);

  /**
   * replaces the old google logout system (commented out above) with the new google logout
   */
  const signOut = () => {

    sessionStorage.removeItem("idToken");
    sessionStorage.removeItem("groups");
    sessionStorage.removeItem("userData");
    sessionStorage.removeItem("loggedIn");
    sessionStorage.removeItem('publishedPagesDB')
    sessionStorage.removeItem("cdtndts");
    sessionStorage.removeItem("dtsdts");
    Cookies.remove("StaffPortalJWT");

    // page management storage items
    sessionStorage.removeItem('SPID')
    sessionStorage.removeItem('SPOption')
    sessionStorage.removeItem("StaffPortalJWT")
    sessionStorage.removeItem('publishedPagesDB')

    googleLogout();
    window.location = "/auth";
  }

  // Include an axios interceptor at the top level here
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      if (error.response.status !== 304) {
        const originalRequest = error.config;
        if (error.response.status !== 401) {
          alert('Task failed successfully?\n-Trillo')
        }
        signOut();
      }

      return Promise.reject(error);
    }
  );

  return <React.Fragment></React.Fragment>;
};

export default AxiosInterceptor;
