import React, { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Radio from "@material-ui/core/Radio";
import Button from "components/CustomButtons/Button";
import axios from "axios";
import update from "immutability-helper";
import ReactTable from "components/ReactTable/ReactTable.js";


import Loading from "components/Loading/Loading";
import LoadingBars from "components/Loading/LoadingBars";
import { doesContractorExist } from "state/invoiceSystem";

import { encryptData } from "assets/jss/EncriptDecrypt/EncryptDecrypt";

import Visibility from "@material-ui/icons/Visibility";
import Edit from "@material-ui/icons/Edit";

const ListContractorsComp = (props) => {
  const { buttons, cols, access, department, contractors, contractorListType } = props

  const [contractorEmail, setContractorEmail] = useState('')
  const [toggleAddContractorLoading, setToggleAddContractorLoading] = useState(false)

  const listContractors = contractors.map((item) => {
    let contractorID = item._id
    if (contractorListType === 'department') {
      contractorID = item.contractorID
    }
    let phone
    if (item.phone) {
      phone = `(${item.phone.slice(0, 3)})${item.phone.slice(3, 6)}-${item.phone.slice(6, 10)}`
    }
    let startDate
    if (item.startDate) {
      let date = item.startDate.split('-')
      startDate = `${date[1]}/${date[2]}/${date[0]}`
    }
    return {
      ...item,
      phone: phone,
      startDate: startDate,
      actions: (
        <React.Fragment>
          {(buttons.view) && (
            <Button
              justIcon
              color="primary"
              title='View'
              onClick={() => {
                let search = encryptData({
                  returnLocation: window.location.href,
                })
                window.location.href = `/portal/contractors/view/${access}/${contractorID}?${search}`
              }}
            >
              <Visibility />
            </Button>
          )}
          {(buttons.edit) && (
            <Button
              justIcon
              color="primary"
              onClick={() => {
                let search = encryptData({
                  returnLocation: window.location.href,
                })
                window.location.href = `/portal/contractors/addEdit/${access}/${contractorID}?${search}`
              }}
              title='Edit'
            >
              <Edit />
            </Button>
          )}
        </React.Fragment>
      )
    }
  })

  const emailChangeHandler = (e) => {
    setContractorEmail(e.target.value)
  }

  const handleAddContractor = async () => {
    if (contractors.find(item => item.contractorEmail === contractorEmail || item.email === contractorEmail)) {
      alert('Contractor already in department.')
    } else {
      setToggleAddContractorLoading(true)
      let contractor = await doesContractorExist(contractorEmail)

      let searchData = {
        returnLocation: window.location.href,
        email: contractorEmail.toLowerCase()
      }

      if (department) {
        searchData.department = department._id
      }

      let search = encryptData(searchData)

      if (contractor.alreadyCreated) {
        window.location.href = `/portal/contractors/addEdit/${access}/${contractor.alreadyCreated}?${search}`
      } else if (contractor.validEmail) {
        window.location.href = `/portal/contractors/addEdit/${access}/Create?${search}`
      } else {
        alert('Invalid Contractor Email.')
      }
      setToggleAddContractorLoading(false)
    }
  }

  const handleManageDepartment = () => {
    if (access && department) {
      let searchData = {
        returnLocation: window.location.href,
      }
      let search = encryptData(searchData)
      location.href = `/portal/contractors/manageDepartment/${access}/${department._id}?${search}`
    } else {
      alert('missing department')
    }
  }

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12} style={{ display: 'flex', justifyContent: 'end' }}>
              {(buttons.addContractor) && (
                <>
                  <TextField
                    placeholder="Email"
                    value={contractorEmail}
                    onChange={emailChangeHandler}
                    helperText={'*Must be a sagebrush email'}
                    style={{ marginRight: '5px', width: '300px' }}
                  />
                  <Button
                    style={{ height: '34px', marginTop: '0px', padding: '15px', paddingTop: '18px' }}
                    disabled={!(contractorEmail.toLowerCase().includes('@sagebrush.church') || contractorEmail.toLowerCase().includes('@sagebrush.team'))}
                    onClick={handleAddContractor}
                    color="primary">
                    {toggleAddContractorLoading ? <LoadingBars /> : 'Add'}
                  </Button>
                </>
              )}
              {(department) && (<>

                {(buttons.manageDepartment) && (
                  <Button
                    style={{ height: '34px', marginTop: '0px', marginLeft: '5px', padding: '15px', paddingTop: '18px' }}
                    onClick={handleManageDepartment}
                    color="primary">
                    {toggleAddContractorLoading ? <LoadingBars /> : 'Manage'}
                  </Button>
                )}
              </>)}
            </GridItem>
            <GridItem xs={12}>
              {listContractors.length > 0 ?
                <React.Fragment>
                  <ReactTable
                    columns={cols}
                    data={listContractors}
                  />
                </React.Fragment>
                :
                <p style={{ textAlign: "center", marginTop: '15px' }}>{contractorListType === 'department' ? 'No Contractors in Department.' : 'No Contractors have been created.'}</p>
              }
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  )
}

const ListContractors = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <ListContractorsComp {...props} />
    </React.Suspense>
  )
}

export default ListContractors