import { atom, selector, selectorFamily, atomFamily } from "recoil";
import axios from "axios";
import api from "../api.config";

const PORTAL_API = 'staff_portal_api'

export const campusAtom = atom({
  key: 'campus',
  default: selector({
    key: 'campusSelector',
    get: async () => { return await getCampuses() }
  })
})

export const buildingsAtom = atom({
  key: 'buildings',
  default: selector({
    key: 'buildingsSelector',
    get: async () => { return await getBuildings() }
  })
})

export const roomsAtom = atom({
  key: 'rooms',
  default: selector({
    key: 'roomsSelector',
    get: async () => { return await getRooms() }
  })
})

export const timezonesAtom = atom({
  key: 'timezones',
  default: selector({
    key: 'timezonesSelector',
    get: async () => {
      return await axios.get(`${api.path}/${PORTAL_API}/event/v1/getTimezones`)
        .then((res) => { return res.data })
    }
  })
})

export const createCampus = async (campus) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/createCampus`, campus).then((res) => { return res.data })
}

export const updateCampus = async (campus) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/updateCampus`, campus)
}

export const deleteCampus = async (campus) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/deleteCampus`, campus)
}

export const getCampuses = async () => {
  return await axios.get(`${api.path}/${PORTAL_API}/event/v1/getCampuses`).then((res) => { return res.data })
}

export const createBuilding = async (building) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/createBuilding`, building).then((res) => { return res.data })
}

export const updateBuilding = async (building) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/updateBuilding`, building)
}

export const deleteBuilding = async (building) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/deleteBuilding`, building)
}

export const getBuildings = async () => {
  return await axios.get(`${api.path}/${PORTAL_API}/event/v1/getBuildings`).then((res) => { return res.data })
}

export const createRoom = async (room) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/createRoom`, room).then((res) => { return res.data })
}

export const updateRoom = async (room) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/updateRoom`, room)
}

export const deleteRoom = async (room) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/deleteRoom`, room)
}

export const getRooms = async () => {
  return await axios.get(`${api.path}/${PORTAL_API}/event/v1/getRooms`).then((res) => { return res.data })
}

export const setOpenHours = async (openHours) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/setOpenHours`, openHours)
}

export const sortDaysOfWeek = (days) => {
  if (!days) {
    return []
  }
  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ]
  let sortedDays = daysOfWeek.map(d => null)
  days.forEach(d => { sortedDays[daysOfWeek.indexOf(d)] = d })
  return sortedDays.filter(d => d !== null)
}


export const resourceAtom = atom({
  key: 'resource',
  default: selector({
    key: 'resourceSelector',
    get: async () => { return await getResources() }
  })
})

export const getResources = async () => {
  return await axios.get(`${api.path}/${PORTAL_API}/event/v1/getResources`).then((res) => { return res.data })
}

export const createResource = async (resource) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/createResource`, resource)
}

export const updateResource = async (resource) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/updateResource`, resource)
}

export const deleteResource = async (resource) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/deleteResource`, resource)
}
