
import { TextField } from '@material-ui/core'
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import React, { useState } from 'react'

const Tags = ({ tags, setTag, setTags, variant, label, error, helperText }) => {
  const [inputValue, setInputValue] = useState('')
  const filter = createFilterOptions();
  const ListboxComponent = React.forwardRef(function ListboxComponent(
    props,
    ref
  ) {
    const { children, ...other } = props;

    return (
      <></>
    );
  });
  return (
    <Autocomplete
      multiple
      fullWidth
      autoSelect
      freeSolo
      forcePopupIcon={false}
      options={[]}
      value={tags}
      disablePortal
      ListboxComponent={ListboxComponent}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          error={error}
          helperText={helperText}
        />
      )}
      inputValue={inputValue}
      onInputChange={(e, v) => {
        const options = v.split(",");
        if (options.length > 1) {
          let v = options[0].trim()
          if (v !== '' && tags.indexOf(v) === -1) {
            setTag(options[0].trim())
          }
          setInputValue('')

        } else {
          setInputValue(v)
        }
      }}
      onChange={(e, v) => {
        let vals = []
        v.forEach(item => {
          item = item.trim()
          if (item) {
            vals.push(item)
          }
        })
        setTags(vals)
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        // Suggest the creation of a new value
        if (params.inputValue !== '' && options.indexOf(params.inputValue) === -1) {
          filtered.push(params.inputValue);
        }

        return filtered;
      }}
    />
  )
}

export default Tags