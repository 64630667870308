import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const AutoCompleteField = React.forwardRef((props, ref) => {
  return (
    <>
      <Autocomplete
        ref={ref}
        disablePortal
        options={props.options}
        getOptionLabel={(option) => `${option.code} - ${option.title}` || ""}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label={props.label} />}
      />
    </>
  );
});

export default AutoCompleteField;
